.page-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.text-content {

}

.scrollable-content-box {
    min-height: 150px;
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #f9f9f9;
    margin-top: 10px;
}