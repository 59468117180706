.skills-detail {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.dropdown-section,
.search-section {
    margin-bottom: 20px;
}

.content-layout {
    display: flex;
    gap: 20px;
    /* Space between the boxes */
}

.content-box-left,
.content-box-right {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    overflow: auto;
    max-height: 500px;
    /* Set a maximum height (adjust as needed) */
}

.content-box-left {
    flex: 2;
    /* Left box takes 2 parts */
}

.content-box-right {
    flex: 1;
    /* Right box takes 1 part */
}

.scrollable-content {
    max-height: 400px;
    /* Height for the scrollable content area */
    overflow-y: auto;
    /* Enable vertical scrolling */
}