.job-detail {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.dropdown-section,
.search-section {
    margin-bottom: 20px;
}

.diff-checker-container {
    margin: 40px 0;
    z-index: 10;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    /* Ensures diff checker takes the full width */
}


.content-layout {
    display: flex;
    gap: 20px;
    /* Space between the columns */
}

.content-layout {
    display: flex;
    /* Use flexbox to lay out items side by side */
    gap: 20px;
    /* Space between the two boxes */
}

.content-box-wrapper {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    overflow: auto;
}

.prompt-section {
    margin-top: 100px;
}

.prompt-input {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.generate-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.generate-button:hover {
    background-color: #218838;
}

.compare-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.compare-button:hover {
    background-color: #0056b3;
}

.left-box {
    width: 33.33%;
    /* Left box takes 1/3 of the width */
}

.right-box {
    width: 66.66%;
    /* Right box takes 2/3 of the width */
}

.chart-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.chart-item p {
    margin: 0;
    font-size: 12px;
    white-space: normal;
    overflow-wrap: break-word;
    flex: 1;
}

.chart-box-donut {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}



.modal {
    position: absolute;
    top: 0;
    /* Ensure the modal covers from the top */
    left: 0;
    /* Ensure the modal covers from the left */
    right: 0;
    /* Ensure the modal covers to the right */
    bottom: 0;
    /* Ensure the modal covers to the bottom */
    background-color: white;
    /* Explicitly set background color */
    padding: 20px;
    z-index: 1000;
    /* Ensures the modal is above other content */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    /* Optional: Add shadow for visibility */
    overflow: auto;
    /* Ensure content overflows are handled */
}

/* Additional styling for ResizableBox to ensure the modal has a visible border */
.resizable-box {
    border-radius: 10px;
    /* Optional: For rounded corners */
    background-color: white;
}