/* JobsTab.css */

.jobs-tab {
    text-align: center;
    margin-top: 20px;
}

/* Prompt Input Section */
.prompt-input-section {
    margin-bottom: 20px;
}

.prompt-input-section h3 {
    margin-bottom: 10px;
}

.prompt-input-section form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.prompt-input-section textarea {
    padding: 8px;
    width: 250px;
    resize: vertical;
    /* Allows vertical resizing of the textarea */
    min-height: 100px;
    max-height: 300px;
    /* Optional: Restrict the maximum height */
}

.prompt-input-section button {
    padding: 8px 16px;
    cursor: pointer;
    margin-top: 10px;
    /* Spacing between the textarea and the button */
}

/* File Upload Section */
.file-upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}

.upload-btn {
    padding: 8px 16px;
    cursor: pointer;
    background-color: #007bff;
    /* Blue background */
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.upload-jd-section label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    /* Adds spacing between input groups */
    width: 100%;
    /* Ensures consistent width */
}


.upload-btn:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}

/* Dropdown Menu */
.jd-method-dropdown {
    padding: 8px;
    width: 250px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    cursor: pointer;
}

/* Create JD Section */
.create-jd-section {
    display: flex;
    gap: 20px;
    /* Space between the two columns */
    margin-top: 20px;
    /* Optional spacing from surrounding content */
}

.jd-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    /* Ensures both columns take equal space */
}

.create-jd-section label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    /* Adds spacing between input groups */
    width: 100%;
    /* Ensures consistent width */
}

.role-input {
    padding: 8px;
    width: 100%;
    /* Adjusts input to fill the column */
    margin-top: 5px;
    /* Adds space between label and input */
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}
.generate-btn {
    padding: 8px 16px;
    cursor: pointer;
    background-color: #28a745;
    /* Green background */
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.generate-btn:hover {
    background-color: #218838;
    /* Darker green on hover */
}

.generate-btn:disabled,
.upload-btn:disabled {
    background-color: #ccc;
    /* Gray background for disabled button */
    cursor: not-allowed;
}

/* Scrollable Box */
.scrollable-box {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 90%;
    max-height: 300px;
    overflow-y: auto;
    background-color: #f9f9f9;
}

