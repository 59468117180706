.dropdown-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.dropdown-section h1 {
    margin: 0;
    flex: 1;
}

.dropdown-section select {
    padding: 5px;
    font-size: 16px;
    background-color: #D3D3D3;
}