.header {
    background: url('../Assets/logo.png') no-repeat center center;
    background-size: cover;
    height: 200px;
    position: relative;
}

.logo {
    position: relative;
    top: 10px;
    left: 20px;
}

.logo img {
    height: 50px;
}

.header-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: #00C8C8;
    /* Original Button color */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.header-button:hover {
    background-color: #009696;
    /* Darker shade on hover */
}