.interview-questions {
    font-family: Arial, sans-serif;
    padding: 20px;
    text-align: left;
    /* Ensure everything is left aligned */
}

.button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.role-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.role-button:hover {
    background-color: #0056b3;
}

.loading {
    font-size: 16px;
    color: #555;
    text-align: left;
    /* Ensure loading text is left-aligned */
}

.questions-container {
    margin-top: 20px;
}

.question-item {
    margin-bottom: 20px;
    text-align: left;
    /* Left align question items */
}

.question-item p {
    margin: 0;
    padding: 0;
}

textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
    /* Make textarea behave like a block element */
}