.skills-validation-section {
    margin-top: 20px;
}

.skills-grid {
    display: flex;
    flex-direction: column;
}

.skills-grid-header {
    display: flex;
    font-weight: bold;
    margin-bottom: 10px;
}

.skills-grid-row {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.header-skill,
.header-proficiency {
    flex: 1;
}

.skill-name {
    flex: 1;
    padding-right: 10px;
}

.proficiency-validation {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.proficiency-range {
    display: flex;
    align-items: center;
}

.proficiency-labels {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-right: 10px;
}

.slider {
    flex: 1;
    margin-right: 10px;
}

.inferred-proficiency {
    margin-top: 10px;
}

.explanation-dropdown {
    margin-top: 10px;
}

.upload-evidence {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.upload-button,
.video-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.upload-button:hover,
.video-button:hover {
    background-color: #0056b3;
}