.select-role-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.search-bar {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}

.search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.go-button {
    padding: 8px 12px;
    margin-left: 5px;
    border: none;
    border-radius: 4px;
    background-color: #00C8C8;
    color: white;
    cursor: pointer;
}

.role-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
}

.role-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    background-color: white;
    color: black;
    transition: background-color 0.3s;
    /* Smooth transition on hover */
}

.role-item.selected {
    background-color: white;
    /* Updated to a more visible color */
    color: black ;
    /* Updated text color to white for contrast */
}

.role-item:hover {
    background-color: #e0e0e0 !important;
    /* Light gray background on hover */
}

.version-count {
    background-color: #007bff;
    color: white;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 0.8rem;
}

/* Styles for the back arrow in versions-container */
.back-arrow {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
    font-weight: bold;
    /* Make arrow bold */
    cursor: pointer;
    color: #007bff;
}
.versions-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 30px;
    /* Add padding to avoid overlap with the arrow */
}

/* List style for versions */
.version-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
}

/* Version item style, matching role-item */
.version-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    background-color: white;
    color: black;
    transition: background-color 0.3s;
}

/* Highlight selected version item */
.version-item.selected {
    background-color: white;
    color: black;
}

.version-item:hover {
    background-color: #e0e0e0 !important;
}

.version-id {
    background-color: #007bff;
    color: white;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 0.8rem;
}

/* New style for versions with an error message */
.error-version {
    background-color: red;
    /* Red background to indicate error */
    color: white;
    /* White text for visibility */
}

/* Tooltip effect for displaying the error message on hover */
.version-id[title] {
    cursor: pointer;
    position: relative;
}

.version-id[title]:hover::after {
    content: attr(title);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f9f9f9;
    color: #333;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    font-size: 0.9rem;
    z-index: 1000;
}