.navbar {
    margin-bottom: 0;
    /* Ensure no extra margin */
    padding-bottom: 0;
    /* Ensure no extra padding */
}

.navbar ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
}

.navbar ul li {
    display: inline;
}

.navbar ul li a {
    text-decoration: none;
    color: #333;
    padding: 10px 20px;
    display: block;
}

.navbar ul li.active a {
    background-color: #00C8C8;
    /* Highlight color */
    color: #fff;
    /* Text color for the highlighted tab */
    border-radius: 4px;
    /* Optional: rounded corners for the highlight */
}