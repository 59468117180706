/* Container styles */
.diff-checker-container {
    width: 100%;
    height: 500px;
    display: flex;
    text-align: left;
    flex-direction: column;
    border: 1px solid #ddd;
}

.diff-checker-header {
    text-align: center;
    margin: 10px 0;
    font-size: 1.5em;
    font-weight: bold;
}

/* Panels */
.diff-checker-panel {
    width: 50%;
    padding: 10px;
    overflow-y: scroll;
    height: 100%;
    border: 1px solid #ccc;
    background: #f9f9f9;
    box-sizing: border-box;
}

/* Header for panels */
.diff-checker-panel h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
}

/* Diff styling */
.diff-checker-diff {
    overflow: hidden;
    padding: 10px;
    font-family: Arial, sans-serif;
}

/* Markdown editor adjustments */
.markdown-editor {
    height: calc(100% - 50px);
}

/* Buttons */
.diff-checker-button {
    margin-top: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.diff-checker-button-save {
    background-color: #4caf50;
    color: white;
}

.diff-checker-button-edit {
    background-color: #2196f3;
    color: white;
}

/* Diff highlights */
.diff-added {
    background-color: lightgreen;
}

.diff-removed {
    background-color: lightcoral;
    text-decoration: line-through;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.SplitPane {
    height: 100%;
}

.Pane {
    overflow-y: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}