.main-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: auto;
    /* Adjust height as needed */
    box-sizing: border-box;
}

.dropdown-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.dropdown-section h1 {
    margin: 0;
    flex: 1;
}

.dropdown-section select {
    padding: 5px;
    font-size: 16px;
    background-color: #D3D3D3;
}

p {
    text-align: left;
    margin: 20px 0;
}

.search-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.search-section input[type="text"] {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    font-size: 16px;
}

.search-section button {
    padding: 8px 16px;
    font-size: 16px;
}

.scrollable-content {
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 20px;
    background-color: #f9f9f9;
}

.content-box {
    max-height: 100%;
    overflow-y: auto;
    white-space: pre-wrap;
    /* Ensure text wraps correctly */
}

/* File upload button */
.file-upload-section {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.file-input {
    margin-right: 10px;
}

.upload-btn {
    padding: 8px 16px;
    background-color: #009696;
    color: white;
    border: none;
    cursor: pointer;
}

.upload-btn:hover {
    background-color: #009696;
}