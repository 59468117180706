ScrollableBox.css
.scrollable-box {
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.scrollable-box::-webkit-scrollbar {
    width: 8px;
}

.scrollable-box::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.scrollable-box::-webkit-scrollbar-track {
    background-color: #eee;
}